// This file was generated with `npm run icons:generate`. Don’t edit directly.
import Icon from 'tailwind/components/Icon/Icon';
import type { MediumIconProps } from 'tailwind/components/Icon/Icon.types';

const SocialFacebookMediumIcon = (props: MediumIconProps) => (
  <Icon {...props} viewBox="0 0 32 32">
    <path
      d="M30 16.0856C30 8.30631 23.732 2 16 2C8.26801 2 2 8.30631 2 16.0856C2 23.116 7.11957 28.9433 13.8125 30V20.1572H10.2578V16.0856H13.8125V12.9823C13.8125 9.45214 15.9027 7.50217 19.1005 7.50217C20.6323 7.50217 22.2344 7.77728 22.2344 7.77728V11.2436H20.4691C18.73 11.2436 18.1875 12.3295 18.1875 13.4434V16.0856H22.0703L21.4496 20.1572H18.1875V30C24.8804 28.9433 30 23.1162 30 16.0856Z"
      fill="currentcolor"
    />
  </Icon>
);

export default SocialFacebookMediumIcon;
