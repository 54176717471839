import React from 'react';

import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import { LinkIcon, LinkIconV2 } from 'components/icons';
import useMartyContext from 'hooks/useMartyContext';

interface Props {
  className?: string;
  product: {
    link: string;
    name: string;
    style: string;
    image: string;
  };
  onClick: Function;
  showLinkClicked: Function;
  iconFillColor?: string;
  isLinkIconV2?: boolean;
  iconName?: string;
  isLinkCopied?: boolean;
}

export const LinkShare = ({ product, className, onClick, iconFillColor, isLinkIconV2, iconName, isLinkCopied, showLinkClicked }: Props) => {
  const { testId } = useMartyContext();
  const shareLink = product.link;

  function copyToClipboard(shareLink: string): any {
    navigator.clipboard.writeText(shareLink);
    showLinkClicked();
    onClick();
  }

  return (
    <a href="#/" onClick={() => copyToClipboard(shareLink)} className={className} data-test-id={testId('shareLinkIcon')}>
      {isLinkIconV2 ? (
        <LinkIconV2 title="Copy Link to Clipboard" size={22} fillcolor={iconFillColor} />
      ) : (
        <LinkIcon title="Copy Link to Clipboard" size={22} fillColor={iconFillColor} />
      )}
      {iconName && <span>{isLinkCopied ? 'Link copied!' : iconName}</span>}
    </a>
  );
};

LinkShare.defaultProps = {
  isLinkIconV2: false
};

export default withErrorBoundary('LinkShare', LinkShare);
