// This file was generated with `npm run icons:generate`. Don’t edit directly.
import Icon from 'tailwind/components/Icon/Icon';
import type { MediumIconProps } from 'tailwind/components/Icon/Icon.types';

const SocialTwitterMediumIcon = (props: MediumIconProps) => (
  <Icon {...props} viewBox="0 0 32 32">
    <path
      d="M18.2833 14.1624L27.2178 4H25.1006L17.3427 12.8238L11.1466 4H4L13.3698 17.3432L4 28H6.11732L14.3098 18.6817L20.8534 28H28L18.2827 14.1624H18.2833ZM15.3833 17.4608L14.4339 16.1321L6.88022 5.55962H10.1323L16.2282 14.0919L17.1776 15.4206L25.1016 26.5113H21.8495L15.3833 17.4613V17.4608Z"
      fill="currentcolor"
    />
  </Icon>
);

export default SocialTwitterMediumIcon;
