import React from 'react';
import queryString from 'query-string';

import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import useMartyContext from 'hooks/useMartyContext';
import SocialTwitterMediumIcon from 'tailwind/components/Icons/SocialTwitterMediumIcon';

interface Props {
  className?: string;
  product: {
    link: string;
    name: string;
    style: string;
    image: string;
  };
  iconFillColor?: string;
  onClick: () => void;
  iconName?: string;
}

export const TwitterShareV2 = ({ product, className, onClick, iconName }: Props) => {
  const { testId } = useMartyContext();
  const twitterLink = 'https://twitter.com/share?' + queryString.stringify({ text: product.name, url: product.link });

  return (
    <a
      className={className}
      data-test-id={testId('twitterShareIconV2')}
      onClick={onClick}
      href={twitterLink}
      rel="noopener noreferrer"
      target="_blank"
    >
      <SocialTwitterMediumIcon size={24} />
      {iconName && <span>{iconName}</span>}
    </a>
  );
};

export default withErrorBoundary('TwitterShareV2', TwitterShareV2);
