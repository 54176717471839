import type { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { selectIsFeaturePdpPaperCuts } from 'selectors/features';
import UtilityDottedTrueToSizeMediumIcon from 'tailwind/components/Icons/UtilityDottedTrueToSizeMediumIcon';

export interface RecommendedSizingLayoutProps {
  children?: ReactNode;
}

const RecommendedSizingLayout = ({ children }: RecommendedSizingLayoutProps) => {
  const isPdpPaperCutsFeatureEnabled = useSelector(selectIsFeaturePdpPaperCuts);

  if (!isPdpPaperCutsFeatureEnabled) {
    return children;
  }

  return (
    <div className="flex h-fit w-fit items-start gap-x-2">
      <UtilityDottedTrueToSizeMediumIcon size={24} className="flex-none" />
      {children}
    </div>
  );
};

export default RecommendedSizingLayout;
