// This file was generated with `npm run icons:generate`. Don’t edit directly.
import Icon from 'tailwind/components/Icon/Icon';
import type { MediumIconProps } from 'tailwind/components/Icon/Icon.types';

const SocialPinterestMediumIcon = (props: MediumIconProps) => (
  <Icon {...props} viewBox="0 0 32 32">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 16C2 21.7352 5.44452 26.6593 10.3749 28.8221C10.3363 27.8469 10.3749 26.669 10.6161 25.6069C10.8863 24.4676 12.4204 17.9793 12.4204 17.9793C12.4204 17.9793 11.9766 17.0814 11.9766 15.7683C11.9766 13.6924 13.1826 12.1476 14.6782 12.1476C15.9518 12.1476 16.5693 13.1034 16.5693 14.2524C16.5693 15.5366 15.7491 17.4483 15.3343 19.2248C14.9869 20.7117 16.0772 21.9186 17.5438 21.9186C20.1971 21.9186 21.9821 18.5103 21.9821 14.4745C21.9821 11.4041 19.9173 9.10621 16.164 9.10621C11.9187 9.10621 9.27498 12.2731 9.27498 15.8069C9.27498 17.0234 9.63198 17.8828 10.2012 18.549C10.4618 18.8579 10.5003 18.9738 10.4039 19.331C10.3363 19.5917 10.1819 20.2097 10.1144 20.451C10.0179 20.8083 9.73811 20.9338 9.41006 20.7986C7.45141 19.9972 6.54445 17.8538 6.54445 15.4497C6.54445 11.4717 9.90214 6.70207 16.5403 6.70207C21.8856 6.70207 25.3977 10.5738 25.3977 14.7255C25.3977 20.2193 22.3487 24.3228 17.8525 24.3228C16.3377 24.3228 14.9194 23.5021 14.4369 22.5752C14.4369 22.5752 13.6265 25.8 13.4528 26.4276C13.1537 27.509 12.5748 28.5903 12.0441 29.4303C13.2984 29.8069 14.6299 30 16.0096 30C23.7381 30 30 23.7338 30 16C30 8.26621 23.7381 2 16.0096 2C8.28119 2 2 8.26621 2 16Z"
      fill="currentcolor"
    />
  </Icon>
);

export default SocialPinterestMediumIcon;
