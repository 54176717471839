import { useSelector } from 'react-redux';

import { cn } from 'helpers/classnames';
import type { AirplaneButtonDimension, AirplaneCacheDimensionOption, SetItemSelection } from 'types/AirplaneCache';
import { capitalize } from 'helpers/index';
import useMartyContext from 'hooks/useMartyContext';
import { useInAssignment } from 'hooks/useHydra';
import { HYDRA_CORE_EXPERIENCE_FUNCTIONALITY_IMPROVEMENTS } from 'constants/hydraTests';
import { selectIsFeaturePdpPaperCuts } from 'selectors/features';

import css from 'styles/components/productdetail/airplaneButton.scss';

interface Props {
  isSelected: boolean;
  isInStock: boolean;
  option: Pick<AirplaneCacheDimensionOption, 'constraintValue' | 'label'>;
  radioButtonName: string;
  setItemSelection: SetItemSelection;
  typeKey: AirplaneButtonDimension;
  namespace: string;
}

/**
 * Code search keywords:
 * airplaneSeatGender, airplaneSeatSize, airplaneSeatWidth,
 * airplaneSeatGenderOos, airplaneSeatSizeOos, airplaneSeatWidthOos
 */

export default function AirplaneButton(props: Props) {
  const { isSelected, isInStock, option, radioButtonName, setItemSelection, typeKey, namespace } = props;

  const { testId } = useMartyContext();
  const hydraCefi = useInAssignment(HYDRA_CORE_EXPERIENCE_FUNCTIONALITY_IMPROVEMENTS);
  const isPdpPaperCutsFeatureEnabled = useSelector(selectIsFeaturePdpPaperCuts);

  const { constraintValue, label } = option;

  const ariaLabel = makeAriaLabel(label, isInStock, typeKey);
  const dimensionNameText = capitalize(typeKey);
  const oosText = isInStock ? '' : 'Oos';

  const inputId = `radio-${constraintValue}-${namespace}`;
  const inputTestId = testId(`airplaneSeat${dimensionNameText}${oosText}Radio`);

  const labelTestId = testId(`airplaneSeat${dimensionNameText}${oosText}`);

  const containerClasses = cn(
    css.container,
    {
      [css.selected]: isSelected,
      [css.oos]: !isInStock && !hydraCefi,
      [css.oosCefi]: !isInStock && hydraCefi
    },
    { [css.paperCutsSelected]: isPdpPaperCutsFeatureEnabled && !isInStock && isSelected },
    { [css.paperCutsSelectedInStock]: isPdpPaperCutsFeatureEnabled && isSelected },
    { [css.paperCuts]: isPdpPaperCutsFeatureEnabled && !isInStock },
    { [css.paperCutsContainer]: isPdpPaperCutsFeatureEnabled }
  );

  return (
    <div className={containerClasses}>
      <input
        type="radio"
        id={inputId}
        data-test-id={inputTestId}
        aria-label={ariaLabel}
        name={radioButtonName}
        value={constraintValue}
        checked={isSelected}
        data-label={label}
        data-track-label={typeKey}
        onChange={setItemSelection}
      />
      <label htmlFor={inputId} data-test-id={labelTestId}>
        <span>{label}</span>
      </label>
    </div>
  );
}

const BUTTON_ARIA_LABEL_PREFIXES: Record<AirplaneButtonDimension, string> = {
  ageGroup: 'Age Group',
  gender: 'Gender',
  singleShoeSide: 'Side',
  size: 'Size',
  sizeRange: 'Size Range',
  width: 'Width'
};

function makeAriaLabel(optionLabel: string, isInStock: boolean, typeKey: AirplaneButtonDimension) {
  const dimensionName = BUTTON_ARIA_LABEL_PREFIXES[typeKey];
  const parts = [dimensionName, optionLabel];
  if (!isInStock) {
    parts.push('is Out of Stock');
  }
  return parts.join(' ');
}
