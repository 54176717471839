import React from 'react';
import queryString from 'query-string';

import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import useMartyContext from 'hooks/useMartyContext';
import SocialPinterestMediumIcon from 'tailwind/components/Icons/SocialPinterestMediumIcon';

interface Props {
  className?: string;
  product: {
    link: string;
    name: string;
    style: string;
    image: string;
  };
  iconFillColor?: string;
  onClick: () => void;
  iconName?: string;
}

export const PinterestShareV2 = ({ product, className, onClick, iconName }: Props) => {
  const { testId } = useMartyContext();
  const pinterestLink =
    'https://pinterest.com/pin/create/button/?' +
    queryString.stringify({
      url: product.link,
      media: product.image,
      description: product.name
    });

  return (
    <a
      className={className}
      data-test-id={testId('pinterestShareIconV2')}
      onClick={onClick}
      href={pinterestLink}
      rel="noreferrer noopener"
      target="_blank"
    >
      <SocialPinterestMediumIcon size={24} />
      {iconName && <span>{iconName}</span>}
    </a>
  );
};

export default withErrorBoundary('PinterestShareV2', PinterestShareV2);
