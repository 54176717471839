import React from 'react';
import queryString from 'query-string';

import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import useMartyContext from 'hooks/useMartyContext';
import SocialFacebookMediumIcon from 'tailwind/components/Icons/SocialFacebookMediumIcon';

interface Props {
  appId: string;
  className?: string;
  product: {
    link: string;
    name: string;
    style: string;
    image: string;
  };
  iconFillColor?: string;
  onClick: () => void;
  iconName?: string;
}

export const FacebookShareV2 = ({ appId, product, className, onClick, iconName }: Props) => {
  const { testId } = useMartyContext();
  const facebookLink =
    'https://www.facebook.com/dialog/feed?' +
    queryString.stringify({
      app_id: appId,
      link: product.link,
      name: product.name,
      caption: product.style,
      description: product.name,
      picture: product.image
    });

  return (
    <a
      className={className}
      data-test-id={testId('facebookShareIconV2')}
      onClick={onClick}
      href={facebookLink}
      rel="noopener noreferrer"
      target="_blank"
    >
      <SocialFacebookMediumIcon size={24} />
      {iconName && <span>{iconName}</span>}
    </a>
  );
};

export default withErrorBoundary('FacebookShareV2', FacebookShareV2);
